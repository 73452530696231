<template>
  <div class="typing-tutor">
    <div class="typing-tutor__header">
      Typing Speed: {{ typingSpeed }} WPM
      <p>Timer: {{ timerMinutes }}:{{ timerSeconds }} seconds</p>
      <p>Accuracy: {{ accuracy }}%</p>
      <p>Errors: {{ errors }}</p>
    </div>
    <div class="typing">
      <textarea
        v-if="!this.detailOfSelected"
        v-model="customText"
        placeholder="Enter your custom text here"
      ></textarea>
      <button @click="startGame" class="ed-btn ed-btn__blim">
        {{ gameStarted ? "Restart Game" : "Start Game" }}
      </button>
    </div>
    <div
      class="typing-tutor__game-section"
      :class="isComplete ? 'typing-tutor__game-section--completed' : ''"
      v-if="gameStarted"
    >
      <p class="typing-tutor__paragraph">
        <span class="typing-tutor__word" v-for="(word, wordIndex) in customTextWords" :key="wordIndex">
          <span
            class="typing-tutor__character"
            v-for="(char, charIndex) in word"
            :key="charIndex + getCharOffset(wordIndex)"
            :class="charClass(charIndex + getCharOffset(wordIndex))"
          >
            {{ char }}
          </span>
          <span>&nbsp;</span>
        </span>
      </p>
      <input
        class="ed-input typing-tutor__input-box"
        type="text"
        v-model="typedText"
        @keydown="handleKeyDown"
        placeholder="Start typing here..."
        ref="typingInput"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextTyper',
  props: {
    detailOfSelected: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      customText: '',
      typedText: '',
      gameStarted: false,
      isComplete: false,
      timer: 0,
      timerInterval: null,
      totalErrors: 0,
      hasError: false,
    };
  },
  computed: {
    customTextWords() {
      return this.customText.split(' ').map((word) => `${word} `);
    },
    typingSpeed() {
      const words = this.typedText
        .split(/\s+/)
        .filter((word) => word.length > 0).length;
      const timeInMinutes = this.timer / 60;
      return timeInMinutes > 0 ? Math.round(words / timeInMinutes) : 0;
    },
    errors() {
      return this.totalErrors;
    },
    accuracy() {
      const totalTyped = this.typedText.length;
      const correctTyped = totalTyped - this.errors;
      return totalTyped > 0 ? Math.round((correctTyped / totalTyped) * 100) : 0;
    },
    timerMinutes() {
      const minutes = Math.floor(this.timer / 60);
      return String(minutes).padStart(2, '0');
    },
    timerSeconds() {
      const seconds = this.timer % 60;
      return String(seconds).padStart(2, '0');
    },
  },
  methods: {
    startGame() {
      if (this.detailOfSelected && this.detailOfSelected.length > 0) {
        this.customText = this.detailOfSelected.replace(/\s+/g, ' ').trim();
        this.gameStarted = true;
        this.typedText = '';
        this.isComplete = false;
        this.timer = 0;
        this.totalErrors = 0;
        this.hasError = false;
        clearInterval(this.timerInterval);
        this.timerInterval = setInterval(() => {
          this.timer += 1;
        }, 1000);
      } else if (this.customText.length > 0) {
        this.gameStarted = true;
        this.typedText = '';
        this.isComplete = false;
        this.totalErrors = 0;
        this.hasError = false;
        clearInterval(this.timerInterval);
        this.timerInterval = setInterval(() => {
          this.timer += 1;
        }, 1000);
      }
      this.$nextTick(() => {
        this.$refs.typingInput.focus();
      });
    },
    handleKeyDown(event) {
      if (this.hasError && event.key !== 'Backspace') {
        event.preventDefault();
        return;
      }
      this.handleTyping(event);
    },
    handleTyping() {
      this.checkTyping();
    },
    checkTyping() {
      this.hasError = false;
      for (let i = 0; i < this.typedText.length; i++) {
        if (this.typedText[i] !== this.customText[i]) {
          this.totalErrors += 1;
          this.hasError = true;
          this.$refs.typingInput.value = this.typedText.substring(0, i + 1);
          this.typedText = this.typedText.substring(0, i + 1);
          return;
        }
      }
      if (
        this.cleanedText(this.typedText) === this.cleanedText(this.customText)
      ) {
        this.isComplete = true;
        clearInterval(this.timerInterval);
      }
    },
    charClass(index) {
      if (index < this.typedText.length) {
        if (this.typedText[index] === this.customText[index]) {
          return 'correct';
        }
        return 'mistake';
      }
      return '';
    },
    getCharOffset(wordIndex) {
      let offset = 0;
      for (let i = 0; i < wordIndex; i++) {
        offset += this.customTextWords[i].length;
      }
      return offset;
    },
    cleanedText(text) {
      return text.replace(/\s+/g, ' ').trim();
    },
  },
  watch: {
    typedText() {
      this.checkTyping();
    },
  },
};
</script>

<style lang="scss">
.input-section,
.game-section {
  margin: 20px 0;
}

textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
}

.typing-tutor {
  border-radius: 1rem;
  box-shadow: 0px 0px 4px 0px #5e585845;
  padding: 1rem;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__word {
    white-space: nowrap;
  }
  &__character {
    font-weight: 400;
    word-spacing: -2px;
  }
  &__game-section {
    padding: 1rem;
    border: 1px solid #c0c0c04f;
    margin-top: 1rem;
    border-radius: 0.5rem;
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    background: #eeeeee;

    &--completed {
      border-color: var(--edvantaYellow-dark);
    }
  }
  &__input-box {
    text-align: center;
    margin: 0 auto;
    outline: none;
  }
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

.correct {
  color: #28922c;
  font-weight: 600;
}

.mistake {
  color: var(--accentRed);
  font-weight: 600;
}

.win-message {
  color: green;
  font-size: 20px;
  margin-top: 10px;
}
</style>
