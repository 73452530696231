<template>
  <div id="typing-tutor">
    <TextTyper :detailOfSelected="detailOfSelected"/>
  </div>
</template>

<script>
import TextTyper from '@/common/components/TextTyper.vue';

export default {
  data() {
    return {
      customText: '',
      typedText: '',
      gameStarted: false,
      isComplete: false,
      timer: 0,
      timerInterval: null,
    };
  },
  components: {
    TextTyper,
  },
  computed: {
    detailOfSelected() {
      if (typeof this.$store.state.payload_detail.description === 'object') {
        return this.$store.state.payload_detail.description.content;
      }
      return this.$store.state.payload_detail.description;
    },
  },
  methods: {
  },
  watch: {
  },
};
</script>

<style lang="scss">
</style>
